@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  overflow: hidden;
  position: relative;

  @include breakpoint('lg', 'min') {
    align-items: center;
    display: flex;
    min-height: 32.5rem;
  }

  > * {
    @include breakpoint('lg', 'min') {
      flex-basis: 100%;
      position: relative;
    }
  }

  &[id] {
    scroll-margin-top: map.get($snaps, 'regular', 'mobile');

    @include breakpoint('lg', 'min') {
      scroll-margin-top: map.get($snaps, 'regular', 'desktop');
    }
  }

  &.mirage {
    color: white;
  }

  [class*="Container_root__"] {
    position: relative;
    z-index: 5;
  }
}

@each $background in map.get($configs, 'background') {
  .#{$background} {
    background-color: map.get($colors, $background);
  }
}

.content {
  padding: map.get($spacers, 'spacing', '7') 0;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
  }
}

.chip {
  @include spacing('md', 'bottom', 'margin');
}

.editor {
  @include spacing('md', 'bottom', 'margin');
}

.product {
  @include spacing('md', 'bottom', 'margin');
}

.title {
  @include spacing('md', 'bottom', 'margin');
  overflow-wrap: break-word;
}

.cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &.is-pill {
    @include breakpoint('xl', 'min') {
      border-radius: map.get($radiuses, '7');
      left: 50%;
      overflow: hidden;
      transform: translateX(-50%);
      width: 140.375rem;
    }

    &::after {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      @include breakpoint('lg', 'max') {
        background-color: rgba(map.get($colors, 'charcoal-charlie'), 0.75);
      }

      @include breakpoint('lg', 'min') {
        background-image: linear-gradient(to right, rgba(map.get($colors, 'charcoal-charlie'), 0.66) 33.33%, rgba(map.get($colors, 'charcoal-charlie'), 0.33) 66.66%, rgba(map.get($colors, 'charcoal-charlie'), 0) 100%);
      }
    }

    & + * {
      color: map.get($colors, 'white');
      position: relative;
    }
  }

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.image {
  padding: map.get($spacers, 'spacing', '7') 0;

  @include breakpoint('lg', 'max') {
    margin-top: - map.get($spacers, 'spacing', '6');
    padding-top: 0;
  }

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
  }

  img {
    height: auto;
    margin: 0 auto;
  }
}

.pill {
  @include breakpoint('lg', 'max') {
    margin-bottom: map.get($spacers, 'spacing', '7');
    padding-top: 50%;
    position: relative;
  }

  @include breakpoint('lg', 'min') {
    bottom: 0;
    left: calc(58.33% + map.get($spacers, 'spacing', '4'));
    position: absolute;
    top: 0;
    width: 62.5rem;
  }

  img {
    border-left-style: solid;
    border-left-width: map.get($borders, '2');
    border-radius: map.get($radiuses, '7');
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;

    @include breakpoint('lg', 'min') {
      border-left-width: map.get($borders, '3');
      height: 32.5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  background-color: transparent;
}

@each $border in map.get($configs, 'border') {
  .#{$border} {
    img {
      border-left-color: map.get($colors, $border);
    }
  }
}
