@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.hero {
  text-align: center;
  padding: map.get($spacers, 'spacing', '7') 0;
  background-color: map.get($colors, 'lily-of-the-valley');

  h1 {
    @include spacing('md', 'bottom', 'margin');
  }
}

.featured {
  padding: map.get($spacers, 'spacing', '12') 0;
  background-color: map.get($colors, 'lily-of-the-valley');
}

.capterraSection {
  padding: map.get($spacers, 'spacing', '12') 0;
  background-color: map.get($colors, 'lily-of-the-valley');

  :nth-child(2) {
    display: flex;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;

    @include breakpoint('lg', 'min') {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.featuredTitle {
  text-align: center;
  margin-bottom: map.get($spacers, 'spacing', '4');
}

.featuredCta {
  text-align: center;
  margin-top: map.get($spacers, 'spacing', '4');

  a {
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: map.get($spacers, 'spacing', '1');
      text-underline-offset: map.get($spacers, 'spacing', '1');
    }
  }
}

.storiesGrid {
  padding-bottom: map.get($spacers, 'spacing', '10');
  scroll-margin-top: map.get($spacers, 'spacing', '14');

  [class*='Card_root__'] {
    height: 100%;
  }

  [class*='Row_root__'] {
    @include breakpoint('md', 'min') {
      margin-left: - map.get($spacers, 'spacing', '4');
      margin-right: - map.get($spacers, 'spacing', '4');
    }
  }

  [class*='Card_links__'] {
    order: 4;
    margin-bottom: 0;
    padding-top: map.get($spacers, 'spacing', '6');
    margin-top: auto;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    a {
      min-width: auto;
      padding: 0 0;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: map.get($spacers, 'spacing', '1');
        text-underline-offset: map.get($spacers, 'spacing', '1');
      }
    }
  }
}

.story {
  @include breakpoint('md', 'min') {
    padding: map.get($spacers, 'spacing', '4');
  }
  @include breakpoint('md', 'max') {
    margin-bottom: map.get($spacers, 'spacing', '5');
  }
}

.filter {
  padding: map.get($spacers, 'spacing', '12') 0
    map.get($spacers, 'spacing', '6') 0;
  position: relative;
  z-index: 5;

  [class*='tabNavItem'] {
    border-radius: 0;
  }

  ul {
    li {
      &.isActive {
        background-color: map.get($colors, 'purple-rain-tint');
        color: map.get($colors, 'purple-rain-active');
        border-radius: map.get($radiuses, '2');
      }
    }
  }
}

.filterWrapper {
  background-color: map.get($colors, 'lily-of-the-valley');
  padding: map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '5');
  border-radius: map.get($radiuses, '4');
  flex: 100%;
  display: flex;
  flex-direction: column;
  gap: map.get($spacers, 'spacing', '4');

  @include breakpoint('lg', 'min') {
    flex-direction: row;
    gap: map.get($spacers, 'spacing', '6');
  }
}

.filterItem {
  input {
    padding: map.get($spacers, 'spacing', '3') map.get($spacers, 'spacing', '4');
  }

  &:not(:last-child) {
    button {
      width: 100%;
      justify-content: space-between;
    }
  }

  @include breakpoint('lg', 'min') {
    flex: 1;
  }

  [class*='Field_inputWrapper__'] {
    margin-bottom: 0;
  }
}

.filterToggle {
  width: 100%;
}

.noResults {
  display: flex;
  justify-content: center;
  flex: 1;

  span {
    padding: map.get($spacers, 'spacing', '4');
    border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
    border-radius: map.get($radiuses, '3');
  }
}

.resetFilter {
  display: flex;
  justify-content: flex-end;

  button {
    @include breakpoint('lg', 'max') {
      margin-top: map.get($spacers, 'spacing', '4');
    }
  }

  @include breakpoint('lg', 'min') {
    max-width: 10%;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.pagination {
  margin-top: map.get($spacers, 'spacing', '8');
}

.paginationButtons {
  display: flex;
  justify-content: center;
  gap: map.get($spacers, 'spacing', '4');
}
