@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  font-size: map.get($typography, 'size', '2');

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: - map.get($spacers, 'spacing', '2');
  }

  li {
    align-items: center;
    display: flex;
    padding: map.get($spacers, 'spacing', '2');
  }

  i {
    flex-shrink: 0;
  }
}

@each $color in map.get($configs, 'color') {
  .#{$color} {
    color: map.get($colors, $color);
    margin-right: map.get($spacers, 'spacing', '2');
  }
}
