@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  [class*='Card_links__'] {
    a {
      min-width: auto;
    }
  }

  [class*='Hero_cover__'] {
    &[class*='Hero_is-pill__'] {
      border-radius: 0;
      left: unset;
      overflow: hidden;
      transform: unset;
      width: 100%;
    }
  }

  .eventCardBorder {
    border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
    border-radius: map.get($radiuses, '4');
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .eventCardContent {
      padding: map.get($spacers, 'spacing', '5');
    }

    &:hover {
      text-decoration: none;
    }
  }

  .eventCardTitle {
    justify-content: flex-start;
  }
}

.tabNavItem {
  font-weight: map.get($typography, 'weight', 'bold');
  padding: map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '6');
  border-radius: 0;

  &:hover {
    text-decoration: none;
  }

  &.tabIsActive {
    color: map.get($colors, 'purple-rain');
    border-bottom: map.get($borders, '2') solid map.get($colors, 'purple-rain');
    border-radius: 0;
  }
}

.section {
  padding: map.get($spacers, 'spacing', '7') 0;
  overflow: hidden;
  scroll-margin-top: map.get($spacers, 'spacing', '14') +
    map.get($spacers, 'spacing', '6');

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
  }

  @include breakpoint('xl', 'min') {
    padding: map.get($spacers, 'spacing', '15') 0;
  }
}

.featuredEvents {
  background-color: map.get($colors, 'white');
  color: map.get($colors, 'charcoal-charlie');

  @include breakpoint('xl', 'min') {
    padding-top: map.get($spacers, 'spacing', '9');
  }

  h3,
  h2 {
    color: map.get($colors, 'charcoal-charlie');
  }

  .image {
    img {
      border-radius: map.get($radiuses, '3');
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  // carousel
  .mobileNavigation {
    display: none;
    margin-top: map.get($spacers, 'spacing', '6');
    margin-right: auto;

    @include breakpoint('md', 'max') {
      display: flex;
    }
  }

  .carouselOverflow {
    position: relative;
    overflow: visible;
    margin: 0 -1rem;

    :global(.swiper) {
      padding: 0 map.get($spacers, 'spacing', '4');
    }
  }

  .hideNavigation {
    display: none;
  }
}

.title {
  @include spacing('lg', 'bottom', 'margin');
}

.stickyNav {
  position: sticky;
  top: 6.25rem;
  z-index: 10;
  background-color: map.get($colors, 'white');
  border-bottom: map.get($borders, '1') solid map.get($colors, 'blackbird-500');

  @include breakpoint('lg', 'min') {
    top: 6.063rem;
  }

  @include breakpoint('lg', 'max') {
    top: 4.65rem;
  }
}

.controls {
  display: flex;
  justify-content: flex-start;
  gap: map.get($spacers, 'spacing', '4');
  padding: map.get($spacers, 'spacing', '5') 0;

  @include breakpoint('sm', 'max') {
    overflow-x: auto;

    a {
      text-align: center;
      min-width: 10rem;
    }

    [class*='Button_medium__'] {
      padding-top: map.get($spacers, 'spacing', '1');
      padding-bottom: map.get($spacers, 'spacing', '1');
    }
  }
}

.community {
  position: relative;
}

.communityContent {
  position: relative;
  z-index: 5;

  h2 {
    color: map.get($colors, 'white');
  }
}

.cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.eventCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  [class*='Chip_root__'] {
    background-color: transparent;
    color: map.get($colors, 'charcoal-charlie');
  }

  &.image-left {
    .eventCardImage {
      display: block;
      padding-top: 25%;
      position: relative;
      width: 100%;

      @include breakpoint('md', 'max') {
        margin-bottom: map.get($spacers, 'spacing', '4');
        padding-top: 50%;
      }

      img {
        border-radius: map.get($radiuses, '3');
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &.image-top {
    .eventCardImage {
      display: block;
      padding-top: 60%;
      position: relative;
      width: 100%;

      img {
        border-radius: map.get($radiuses, '3');
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &.image-left {
    @include breakpoint('md', 'min') {
      display: flex;
      flex-direction: row;
      gap: map.get($spacers, 'spacing', '10');
      align-items: center;

      > div {
        flex: 1;
      }
    }
  }

  &.image-background {
    position: relative;
    display: flex;

    .eventCardImage {
      width: 100%;
      position: relative;
      padding-top: 122.37%;

      @include breakpoint('sm', 'max') {
        padding-top: 108.41%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: map.get($radiuses, '3');
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .eventCardContent {
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: map.get($spacers, 'spacing', '5');
    }

    .eventCardTitle {
      margin-top: auto;
    }

    .eventTitle {
      margin-bottom: 0.25rem;
    }

    .eventRegister {
      margin-top: 0;
      padding-top: map.get($spacers, 'spacing', '5');
    }
  }

  &.yellow {
    color: map.get($colors, 'white');

    h3 {
      color: map.get($colors, 'white');
    }

    a {
      &:hover {
        text-decoration-color: map.get($colors, 'yellow-submarine');
      }
    }

    .eventDetail {
      color: map.get($colors, 'white');

      i {
        color: map.get($colors, 'yellow-submarine');
      }
    }
  }

  &.purple {
    .eventDetail {
      i {
        color: map.get($colors, 'purple-rain');
      }
    }
  }

  &.inverted {
    color: map.get($colors, 'white');

    h3 {
      color: map.get($colors, 'white');
    }

    a {
      &:hover {
        text-decoration-color: map.get($colors, 'purple-rain');
      }
    }
  }
}

.eventCardImage {
  img {
    border-radius: map.get($radiuses, '3');
    height: auto;
  }

  @include breakpoint('md', 'max') {
    margin-bottom: map.get($spacers, 'spacing', '2');
  }
}

.eventTitle {
  margin-bottom: map.get($spacers, 'spacing', '4');
}

.eventDetails {
  display: grid;
  gap: map.get($spacers, 'spacing', '1');

  @include breakpoint('xl', 'max') {
    flex-direction: column;
  }
}

.eventDetail {
  display: flex;
  gap: map.get($spacers, 'spacing', '3');

  i {
    flex: 0 0 auto;
    margin-top: map.get($spacers, 'spacing', '1');
  }

  p {
    text-transform: capitalize;
  }
}

.eventTypes {
  gap: map.get($spacers, 'spacing', '3');
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: map.get($spacers, 'spacing', '4');

  span {
    text-transform: capitalize;
  }

  &.eventTypePurple {
    span {
      border-color: map.get($colors, 'purple-rain');
    }
  }

  &.eventTypeBlue {
    span {
      border-color: map.get($colors, 'blue-moon');
    }
  }

  &.eventTypeYellow {
    span {
      border-color: map.get($colors, 'yellow-submarine');
    }
  }
}

.textLink {
  @include spacing('md', 'top', 'padding');
  margin-top: auto;
  display: inline-flex;
  align-items: center;
  gap: map.get($spacers, 'spacing', '3');
  color: map.get($colors, 'purple-rain');
}

.findEventsGrid {
  width: 100%;

  .eventDetail {
    color: map.get($colors, 'charcoal-charlie');
  }

  .eventDescription {
    color: map.get($colors, 'charcoal-charlie');
    margin-bottom: map.get($spacers, 'spacing', '4');
  }

  h3 {
    color: map.get($colors, 'charcoal-charlie');
  }

  .eventDetails {
    flex-direction: column;
    gap: map.get($spacers, 'spacing', '2');
  }

  .eventRegister {
    padding-left: 0;
  }
}

.eventRegister {
  margin-top: auto;
  padding-top: map.get($spacers, 'spacing', '4');
}

.eventLinkWrapper {
  min-height: 100%;
  display: flex;
  align-items: flex-start;

  &:hover {
    text-decoration: none;
    outline: map.get($borders, '1') solid map.get($colors, 'purple-rain');
    border-color: map.get($colors, 'purple-rain');
    cursor: pointer;

    h3,
    p {
      text-decoration: none;
    }
  }
}

.eventCardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// events redesign
.findEvents {
  position: relative;
  padding-top: map.get($spacers, 'spacing', '5');

  .title {
    display: none;

    @include breakpoint('xl', 'min') {
      display: block;
    }
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    gap: map.get($spacers, 'spacing', '6');

    @include breakpoint('xl', 'min') {
      flex-direction: row;
      gap: map.get($spacers, 'spacing', '2');
    }
  }

  .mobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: map.get($spacers, 'spacing', '4');
    width: 100%;

    @include breakpoint('xl', 'min') {
      display: none;
    }
  }

  .mobileFilterButton {
    display: flex;
    align-items: center;
    padding: map.get($spacers, 'spacing', '3') map.get($spacers, 'spacing', '4');
    gap: map.get($spacers, 'spacing', '3');

    i {
      margin: 0;
    }

    @include breakpoint('xl', 'min') {
      display: none;
    }
  }

  .desktopFilters {
    display: none;

    @include breakpoint('xl', 'min') {
      display: block;
      width: 17.75rem;
      border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
      border-radius: map.get($radiuses, '3');
    }
  }

  .filterModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483001; // above chat widget
    background-color: map.get($colors, 'white');
    height: 100%;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.2s ease-in-out;

    &.isActive {
      transform: translateY(0);
    }

    @include breakpoint('xl', 'min') {
      display: none;
    }
  }

  .modalContent {
    height: calc(100% - map.get($spacers, 'spacing', '12'));
    display: flex;
    flex-direction: column;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: map.get($spacers, 'spacing', '5') map.get($spacers, 'spacing', '4');
    background-color: map.get($colors, 'lily-of-the-valley');
  }

  .modalTitle {
    font-size: map.get($typography, 'size', 'lg');
    font-weight: map.get($typography, 'weight', 'semibold');
  }

  .closeButton {
    color: map.get($colors, 'charcoal-charlie');
  }

  .modalBody {
    height: 100%;
    overflow: auto;
    padding-bottom: map.get($spacers, 'spacing', '12');
    flex: 1;
  }

  .modalFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: map.get($spacers, 'spacing', '4');
    border-top: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
    background-color: map.get($colors, 'white');

    .clearButton {
      display: block;
    }
  }

  .filterContent {
    padding: 0 1.375rem 0 map.get($spacers, 'spacing', '4');

    @include breakpoint('xl', 'min') {
      padding: map.get($spacers, 'spacing', '3')
        map.get($spacers, 'spacing', '5') map.get($spacers, 'spacing', '5');
    }
  }

  .accordionItem {
    border-top: map.get($borders, '1') solid map.get($colors, 'blackbird-500');

    &:first-child {
      border-top: none;
    }

    @include breakpoint('xl', 'min') {
      &:last-child {
        border-bottom: map.get($borders, '1') solid
          map.get($colors, 'blackbird-500');
      }
    }
  }

  .accordionHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    transition: background-color 0.2s ease;
    background-color: map.get($colors, 'white');
    padding: map.get($spacers, 'spacing', '4') 0;
    cursor: pointer;
    color: map.get($colors, 'charcoal-charlie');

    @include breakpoint('xl', 'min') {
      background-color: transparent;
    }
  }

  .accordionHeaderOpen {
    i {
      transform: rotate(180deg);
    }
  }

  .accordionChevron {
    transition: transform 0.2s ease;
    color: map.get($colors, 'charcoal-charlie');

    &.accordionChevronOpen {
      transform: rotate(180deg);
    }
  }

  .accordionTitle {
    font-weight: map.get($typography, 'weight', 'semibold');
  }

  .checkboxGroup {
    display: flex;
    flex-direction: column;
  }

  .checkbox {
    display: flex;
    align-items: center;
    gap: map.get($spacers, 'spacing', '3');
    margin: map.get($spacers, 'spacing', '3') 0;
    cursor: pointer;
    text-transform: capitalize;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: map.get($spacers, 'spacing', '4');
    }

    input {
      &[type='checkbox'] {
        display: inline-flex;
        -webkit-appearance: none;
        appearance: none;
        background-color: map.get($colors, 'transparent');
        margin: 0;
        width: map.get($spacers, 'spacing', '4') +
          map.get($spacers, 'spacing', '2');
        height: map.get($spacers, 'spacing', '4') +
          map.get($spacers, 'spacing', '2');
        border: map.get($borders, '1') solid map.get($colors, 'purple-rain');
        border-radius: map.get($radiuses, '1');
        position: relative;

        &:checked {
          background-color: map.get($colors, 'purple-rain');

          &:after {
            position: absolute;
            font: inherit;
            font-size: map.get($typography, 'size', '2');
            top: 40%;
            left: 50%;
            content: '\02143';
            transform: translate(-50%, -50%) rotate(40deg);
            color: map.get($colors, 'white');
          }
        }
      }
    }

    span {
      font-size: map.get($typography, 'size', 'base');
      color: map.get($colors, 'charcoal-charlie');
    }
  }

  .eventsGrid {
    display: grid;
    gap: map.get($spacers, 'spacing', '6');

    @include breakpoint('sm', 'min') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint('lg', 'min') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .pagination {
    margin-top: map.get($spacers, 'spacing', '6');
    display: flex;
    justify-content: center;
  }

  .paginationButtons {
    display: flex;
    gap: map.get($spacers, 'spacing', '4');
  }

  .noResults {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .clearButton,
  .applyButton {
    padding: map.get($spacers, 'spacing', '3') map.get($spacers, 'spacing', '4');
    border-radius: map.get($radiuses, '2');

    @include breakpoint('xl', 'min') {
      font-weight: map.get($typography, 'weight', 'medium');
      flex: 1;
    }
  }

  .clearButton {
    color: map.get($colors, 'purple-rain');
    padding: 0;
    display: none;

    &:hover {
      border-color: map.get($colors, 'purple-rain');
      color: map.get($colors, 'purple-rain');
    }

    @include breakpoint('xl', 'min') {
      border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
      width: 100%;
      margin-top: map.get($spacers, 'spacing', '5');
      padding: map.get($spacers, 'spacing', '3');
      display: block;
    }
  }

  .applyButton {
    background-color: map.get($colors, 'purple-rain');
    color: map.get($colors, 'white');
    padding: map.get($spacers, 'spacing', '3') map.get($spacers, 'spacing', '4');

    &:hover {
      background-color: map.get($colors, 'purple-rain-active');
    }
  }
}

.noEvents {
  margin: map.get($spacers, 'spacing', '6') 0;

  .noResults {
    text-align: center;
  }
}
