@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

$accordion-inset-padding: map.get($spacers, 'spacing', '4');

.accordion {
  display: flex;
  flex-direction: column;
  gap: map.get($spacers, 'spacing', '4');
}

.accordionRow {
  --accordion-row-border-color: #{map.get($colors, 'blackbird-700')};

  border-radius: map.get($radiuses, '3');
  border-width: 2px;
  border-style: solid;
  border-color: var(--accordion-row-border-color, transparent);
  background-color: var(--accordion-row-bg-color, transparent);
  color: var(--accordion-row-font-color, inherit);

  &:hover {
    --accordion-row-title-color: #{map.get($colors, 'purple-rain-active')};
  }

  // ... Black backgrounds
  [class*='mirage'] & {
    --accordion-row-border-color: #{map.get($colors, 'blackbird-500')};
    --accordion-row-title-color: #{map.get($colors, 'white')};
    --accordion-row-font-color: #{map.get($colors, 'white')};

    &:hover {
      --accordion-row-bg-color: #{map.get($colors, 'charcoal-charlie')} !important;
    }
  }

  &.active {
    .accordionRowContent {
      display: block;
    }

    .accordionRowHeader {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.accordionRowHeader {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  appearance: none;
  border: none;
  padding: $accordion-inset-padding;
  background: none;
  cursor: pointer;

  &:focus {
    border: none;
    outline: none;
  }

  .accordionRowHeaderTitle,
  svg {
    color: var(
      --accordion-row-title-color,
      map.get($colors, 'charcoal-charlie')
    );
  }

  svg {
    width: 1.25em;
    height: 1.25em;
    flex-shrink: 0;
  }
}

.accordionRowContent {
  display: none;
  padding: 0 $accordion-inset-padding $accordion-inset-padding;

  .accordionRowContentChecks {
    ul {
      justify-content: flex-start;
      flex-direction: column;

      li {
        i {
          line-height: 25.6px;
        }

        gap: map.get($spacers, 'spacing', '2');
        text-align: left;
        align-items: baseline;
        font-size: map.get($typography, 'size', '3');
      }
    }
  }

  .accordionRowContentEditor {
    text-align: left;

    img {
      height: auto;
    }
  }
}
