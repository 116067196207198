@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  background-color: map.get($colors, 'lily-of-the-valley');
  border-radius: map.get($radiuses, '4');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  padding: map.get($spacers, 'spacing', '4');
  scroll-margin-top: map.get($snaps, 'offset', 'mobile');

  @include spacing('md', 'bottom', 'margin');

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '5');
    scroll-margin-top: map.get($snaps, 'offset', 'desktop');
  }
}

.title {
  @include spacing('md', 'bottom', 'margin');
}

.cta {
  white-space: nowrap;

  @include breakpoint('md', 'max') {
    min-width: 100%;
  }
}

.ctaButtonWrapper {
  display: flex;
  margin-bottom: map.get($spacers, 'spacing', '4');
  gap: map.get($spacers, 'spacing', '3');

  @include breakpoint('xl', 'min') {
    flex-direction: column;
  }

  @include breakpoint('xl', 'max') {
    flex-direction: row;
  }

  @include breakpoint('lg', 'max') {
    flex-direction: column;
  }
}

.body {
  border-top: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  @include spacing('md', 'top', 'padding');

  @include breakpoint('xl', 'max') {
    margin-top: map.get($spacers, 'spacing', '4');
  }
}

.featuredList {
  flex-basis: 70%;

  @include breakpoint('xl', 'max') {
    order: 2;
  }

  ul {
    @include spacing('md', 'bottom', 'margin');
  }

  li {
    display: flex;
    gap: map.get($spacers, 'spacing', '2');
    align-items: center;
    margin-bottom: map.get($spacers, 'spacing', '2');
  }
}

.ctaWrapper {
  display: flex;
  justify-content: space-between;

  @include breakpoint('xl', 'max') {
    flex-direction: column;
  }
}

.leftPart {
  @include breakpoint('md', 'min') {
    padding-right: map.get($spacers, 'spacing', '9');
  }
}

.rightPart {
  text-align: right;

  @include breakpoint('lg', 'min') {
    padding-left: map.get($spacers, 'spacing', '4') * 4;
  }

  @include breakpoint('lg', 'max') {
    margin-top: map.get($spacers, 'spacing', '6');
  }
}

.previewLinks {
  display: flex;
  flex-direction: column;
  text-align: center;

  gap: map.get($spacers, 'spacing', '4');
  @include spacing('md', 'top', 'margin');

  a {
    text-decoration: underline;
    text-decoration-thickness: map.get($spacers, 'spacing', '1');
    text-underline-offset: map.get($spacers, 'spacing', '1');
  }
}

.featuredImage {
  position: relative;
  padding: map.get($spacers, 'spacing', '4');
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '3');

  margin-bottom: map.get($spacers, 'spacing', '6');

  @include breakpoint('md', 'min') {
    margin-bottom: map.get($spacers, 'spacing', '8');
  }

  &:hover {
    cursor: pointer;
    box-shadow: map.get($shadows, '1');
  }

  img {
    height: auto;
  }
}

.mobileOverlay {
  bottom: -2rem;
  display: block;
  height: 100%;
  height: auto;
  left: -2rem;
  position: absolute;
  width: 50%;

  @include breakpoint('sm', 'max') {
    left: -1rem;
    bottom: -1rem;
  }
}

.zoom {
  position: absolute;
  bottom: map.get($spacers, 'spacing', '5');
  right: map.get($spacers, 'spacing', '5');
  z-index: 2;
}

.header {
  h2 {
    margin-top: 0;
    padding-top: 0;

    &::before {
      display: none;
    }
  }
}
