@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;

.question {
  border-radius: map.get($radiuses, '4');

  &:not(:last-child) {
    margin-bottom: map.get($spacers, 'spacing', '4');
  }

  .guide & {
    background-color: map.get($colors, 'white');
    border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  }

  .page & {
    background-color: map.get($colors, 'lily-of-the-valley');

    [class*='lily-of-the-valley'] & {
      background-color: map.get($colors, 'white');
    }
  }
}

.title {
  .guide & {
    font-weight: map.get($typography, 'weight', 'medium');
  }

  .page & {
    font-weight: map.get($typography, 'weight', 'bold');

    @include breakpoint('lg', 'min') {
      font-size: map.get($typography, 'size', '4');
    }
  }
}

.button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-weight: inherit;
  justify-content: space-between;
  padding: map.get($spacers, 'spacing', '4');
  text-align: left;
  width: 100%;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '5');
  }

  &:hover,
  .is-active & {
    color: map.get($colors, 'purple-rain-active');
  }

  &:active {
    transform: translateY(map.get($spacers, 'spacing', '1'));
  }

  i {
    color: map.get($colors, 'purple-rain-active');

    .is-active & {
      transform: rotate(-180deg);
    }
  }
}

.editor {
  display: none;
  padding: 0 map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '4')
    map.get($spacers, 'spacing', '4');
  text-align: left;

  @include breakpoint('lg', 'min') {
    padding: 0 map.get($spacers, 'spacing', '5')
      map.get($spacers, 'spacing', '5') map.get($spacers, 'spacing', '5');
  }

  .is-active & {
    display: block;
  }

  img {
    height: auto;
  }
}
