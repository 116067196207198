@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;
@use '@shared/styles/extends/content' as *;

.root {
  position: relative;

  [class*="TableOfContent_tocMobile___"] {
    @include breakpoint('md', 'max') {
      margin-bottom: map.get($spacers, 'spacing', '4');
    }

    @include breakpoint('md', 'min') {
      display: none;
    }
  }
}

.hero {
  padding: map.get($spacers, 'spacing', '7') 0;
  position: relative;
  text-align: center;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0 map.get($spacers, 'spacing', '6') 0;
  }

  .title {
    @include spacing('md', 'bottom', 'margin');
  }

  > * {
    position: relative;
  }
}

.featuredImage {
  aspect-ratio: 16/6;

  img {
    border-radius: map.get($radiuses, '4');
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16/6;

    @include breakpoint('sm', 'max') {
      border-radius: 0;
    }
  }
}

.post {
  padding: map.get($spacers, 'spacing', '7') 0;

  @include breakpoint('lg', 'max') {
    padding: 0 0;
  }
}

.group {
  padding-bottom: 0;
  margin-bottom: map.get($spacers, 'spacing', '12');

  &.withRelatedPosts {
    margin-bottom: map.get($spacers, 'spacing', '7') + 16.25rem;

    @include breakpoint('lg', 'min') {
      margin-bottom: map.get($spacers, 'spacing', '12') + 15rem;
    }

    @include breakpoint('xl', 'min') {
      margin-bottom: map.get($spacers, 'spacing', '15') + 16.25rem;
    }
  }
}

.chip {
  @include spacing('md', 'bottom', 'margin');
}

.content {
  word-wrap: break-word;
  padding-top: map.get($spacers, 'spacing', '4');
  @extend %content;
  @include spacing('lg', 'bottom', 'margin');
}

.author {
  border-top: map.get($borders, '1') solid map.get($colors, 'blackbird-500');

  @include spacing('lg', 'bottom', 'margin');
  @include spacing('lg', 'top', 'padding');
}

.related {
  background-color: map.get($colors, 'lily-of-the-valley');
  padding: map.get($spacers, 'spacing', '8') map.get($spacers, 'spacing', '4');

  @include spacing('lg', 'bottom', 'margin');

  @include breakpoint('sm', 'max') {
    margin-left: - map.get($spacers, 'spacing', '4');
    margin-right: - map.get($spacers, 'spacing', '4');
  }

  @include breakpoint('sm', 'min') {
    border-radius: map.get($radiuses, '4');
    padding: map.get($spacers, 'spacing', '4');
  }

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '6');
  }

  .title {
    @include spacing('md', 'bottom', 'margin');
  }
}

.blocks {

  .title {
    @include spacing('lg', 'bottom', 'margin');
  }
}

.tocMobilePlaceholder {
  min-height: 4.75rem;
  width: 100%;
  flex-direction: column;
  display: none;

  @include breakpoint('lg', 'max') {
    display: flex;
  }
}