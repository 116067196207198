@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  @include spacing('lg', 'bottom', 'margin');

  .firstHeader {
    align-items: start;
  }
}

.image {
  max-width: 7.5rem;
  height: auto;
  background-color: map.get($colors, 'white');
  box-shadow: map.get($shadows, '1');
  border-radius: map.get($radiuses, '3');
  padding: map.get($spacers, 'spacing', '2');

  @include breakpoint('sm', 'max') {
    max-width: 3.5rem;
  }
}

.title {
  color: map.get($colors, 'purple-rain');
}

.header {
  display: flex;
  align-items: center;
  width: 100%;

  @include breakpoint('sm', 'max') {
    gap: map.get($spacers, 'spacing', '4');
  }

  img {
    margin: 0 0 0 auto;
  }

  .logoImage {
    height: 100%;
  }
}

.featured {
  padding: map.get($spacers, 'spacing', '5') map.get($spacers, 'spacing', '6');
  border-radius: map.get($radiuses, '3');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');

  @include spacing('md', 'top', 'margin');
  @include spacing('md', 'bottom', 'margin');

  @include breakpoint('sm', 'max') {
    padding: map.get($spacers, 'spacing', '3') map.get($spacers, 'spacing', '4');
  }
}

.buttons {
  margin: map.get($spacers, 'spacing', '4') 0;
  display: flex;
  flex-wrap: wrap;
  gap: map.get($spacers, 'spacing', '4');

  @include breakpoint('sm', 'max') {
    [class*='Button_root'] {
      width: 100%;
    }
  }
}

.number {
  @include breakpoint('sm', 'min') {
    margin-right: 3rem;
  }
}

.apps {
  padding-top: map.get($spacers, 'spacing', '4');
  border-top: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  display: flex;
  justify-content: space-between;

  @include breakpoint('sm', 'max') {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-bottom: map.get($spacers, 'spacing', '4');
  }
}

.cta {
  @include spacing('md', 'top', 'margin');

  a {
    font-weight: map.get($typography, 'weight', 'bold');

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: map.get($spacers, 'spacing', '1');
      text-underline-offset: map.get($spacers, 'spacing', '1');
    }
  }
}

.body {
  @include spacing('md', 'top', 'margin');
}
