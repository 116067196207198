@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;


.root {
  position: relative;
}

.select {
  display: none;
  width: 100%;
  z-index: 10;
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '3');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  left: 0;
  padding: map.get($spacers, 'spacing', '4');
  position: absolute;
  top: 100%;
  white-space: nowrap;
  z-index: 10;
  max-height: 25rem;
  overflow-y: auto;

  ul {
    width: 100%;

    li {
      text-align: left;
      cursor: pointer;

      &.isActive {
        background-color: map.get($colors, 'purple-rain-tint');
        color: map.get($colors, 'purple-rain-active');
        border-radius: map.get($radiuses, '2');
      }

      &:hover {
        span {
          background-color: map.get($colors, 'purple-rain-tint');
          color: map.get($colors, 'purple-rain-active');
        }
      }

      span {
        color: map.get($colors, 'charcoal-charlie');
        padding: map.get($spacers, 'spacing', '3')
          map.get($spacers, 'spacing', '3');
        display: block;
        border-radius: map.get($radiuses, '1');
      }
    }

    @include breakpoint('md', 'max') {
      max-height: 25rem;
      overflow: auto;
    }
  }

  &.isActive {
    display: flex;
  }
}