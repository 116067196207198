@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  text-align: center;

  &[id] {
    scroll-margin-top: map.get($snaps, 'regular', 'mobile');

    @include breakpoint('lg', 'min') {
      scroll-margin-top: map.get($snaps, 'regular', 'desktop');
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    top: 0;
    left: 0;
    object-fit: contain;
    width: 100%;
    height: 18rem;

    @include breakpoint('lg', 'min') {
      height: 22rem;
    }
  }
}

.withImage {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: map.get($spacers, 'spacing', '6');
  
    @include breakpoint('lg', 'min') {
      text-align: left;
    }
  
    > * {
      margin: 0 !important;
    }
  
    .links {
      ul {
        @include breakpoint('lg', 'min') {
          justify-content: flex-start;
        }
      }
    }
  }

  .checks {
    ul {
      @include breakpoint('lg', 'min') {
        justify-content: flex-start;
      }
    }
  }
}

.isShortcode {
  [class*='Container_root'] {
    padding: 0;
  }
}

.gradient {
  background-image: linear-gradient(
    to bottom,
    map.get($colors, 'charcoal-charlie'),
    map.get($colors, 'purple-rain')
  );
  color: map.get($colors, 'white');
}

.lily-of-the-valley {
  background-color: map.get($colors, 'lily-of-the-valley');
}

.purple-rain {
  background-color: map.get($colors, 'purple-rain');
  color: map.get($colors, 'white');
}

.spacing {
  padding-left: map.get($spacers, 'spacing', '10');
  padding-right: map.get($spacers, 'spacing', '10');

  .container & {
    padding-bottom: map.get($spacers, 'spacing', '6');
    padding-top: map.get($spacers, 'spacing', '6');

    @include breakpoint('sm', 'max') {
      margin-left: - map.get($spacers, 'spacing', '4');
      margin-right: - map.get($spacers, 'spacing', '4');
    }

    @include breakpoint('sm', 'min') {
      border-radius: map.get($radiuses, '4');
    }

    @include breakpoint('lg', 'min') {
      padding-bottom: map.get($spacers, 'spacing', '6');
      padding-top: map.get($spacers, 'spacing', '6');
    }
  }

  .full & {
    padding-bottom: map.get($spacers, 'spacing', '7');
    padding-top: map.get($spacers, 'spacing', '7');

    @include breakpoint('lg', 'min') {
      padding-bottom: map.get($spacers, 'spacing', '12');
      padding-top: map.get($spacers, 'spacing', '12');
    }
  }
}

.checks {
  @include spacing('md', 'bottom', 'margin');
}

.editor {
  @include spacing('md', 'bottom', 'margin');
}

.embed {
  @include spacing('md', 'bottom', 'margin');
}

.links {
  margin-top: map.get($spacers, 'spacing', '6');

  @include spacing('md', 'bottom', 'margin');

  ul {
    display: flex;
    gap: map.get($spacers, 'spacing', '4');
    margin: 0;
    padding: 0;
    justify-content: center;
  }

  li {
    display: inline-block;
    list-style-type: none !important;
    padding: 0;
  }

  a {
    min-width: 100%;
  }
}

.product {
  @include spacing('md', 'bottom', 'margin');
}

.title {
  @include spacing('md', 'bottom', 'margin');
}

.search {
  margin-left: auto;
  margin-right: auto;
  max-width: 45rem;
  position: relative;

  @include spacing('md', 'bottom', 'margin');

  .field {
    padding-right: map.get($spacers, 'spacing', '8');
  }

  .button {
    position: absolute;
    right: map.get($spacers, 'spacing', '4');
    top: 50%;
    transform: translateY(-50%);

    &:active {
      transform: translateY(calc(-50% + map.get($spacers, 'spacing', '1')));
    }
  }
}

.iconsList {
  @include spacing('md', 'bottom', 'margin');

  ul {
    gap: map.get($spacers, 'spacing', '3');
  }
}

.viaShortcode {
  h3 {
    font-size: map.get($typography, 'size', '6');
  }
}