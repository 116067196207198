@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;


.root {
  margin-bottom: map.get($spacers, 'spacing', '9');

  @include breakpoint('md', 'min') {
    display: flex;
  }
}

.avatar {
  height: 9.375rem;
  position: relative;
  width: 9.375rem;

  @include breakpoint('md', 'max') {
    margin: 0 auto map.get($spacers, 'spacing', '7') auto;
  }

  @include breakpoint('md', 'min') {
    flex-shrink: 0;
    margin-right: map.get($spacers, 'spacing', '5');
  }

  @include breakpoint('lg', 'min') {
    margin-right: map.get($spacers, 'spacing', '7');
  }

  > img {
    border-radius: 50%;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.social {
  bottom: math.div(map.get($spacers, 'spacing', '7'), -1);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.content {
  @include breakpoint('md', 'min') {
    flex-shrink: 1;
  }
}

.author {
  color: map.get($colors, 'blackbird-700');
  margin-bottom: map.get($spacers, 'spacing', '3');
}

.description {
  margin-top: map.get($spacers, 'spacing', '3');
}

.viewMore {
  color: map.get($colors, 'charcoal-charlie');

  &:hover {
    color: map.get($colors, 'charcoal-charlie');
  }
}